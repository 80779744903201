@import '__importable.scss';
@import '@styles/colors';
@import '@styles/variables';

.container {
	height: 100%;
	border-radius: 1.6rem;
	border: 1px solid $gray-2;
	width: 100%;
	aspect-ratio: 4/3;
	overflow: hidden !important;

	ul {
		height: 100% !important;

		li {
			height: 100% !important;
		}
	}

	@media screen and (min-width: $media-lg) {
		aspect-ratio: 1/1;
	}

	@media screen and (min-width: $media-xl) {
		max-height: 52.5rem;

		ul {
			max-height: 52.5rem;
		}
	}

	@media screen and (min-width: $media-xxl) {
		max-height: 64rem;

		ul {
			max-height: 64rem;
		}
	}

	@media screen and (max-width: $mq-small) {
		border-radius: unset;
		border: unset;
	}
}


.swiper-slide {
	position: relative;

	/* Overrides styles for last carousel image */
	&[data-swiper-slide-index='2'] {
		div[data-product-type='TOP_FRAME'] {
			img {
				padding: 0;
				object-fit: cover;
				mix-blend-mode: unset;
			}
		}
	}

	&[data-swiper-slide-index='4'] {
		div[data-product-type^='TOP_FRAME__'] {
			img {
				height: 250%;
				max-width: 250%;
				width: 250%;
				position: absolute;
				left: 5%;
				bottom: -40%;
			}
		}
	}

	div[data-product-type='LIFESTYLE'] {
		img {
			padding: 0;
			object-fit: cover;
			mix-blend-mode: unset;
		}
	}
}

// zoom in top frame without 3/4 images
.without-3-4-image {
	&[data-swiper-slide-index='1'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				height: 200%;
				max-width: 200%;
				width: 200%;
				position: absolute;
				left: -50%;
			}
		}
	}

	&[data-swiper-slide-index='2'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				height: 250%;
				max-width: 250%;
				width: 250%;
				position: absolute;
				left: 5%;
				bottom: -40%;
			}
		}
	}
}

// zoom in top frame with 3/4 images
.with-3-4-image {
	&[data-swiper-slide-index='2'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				height: 200%;
				max-width: 200%;
				width: 200%;
				position: absolute;
				left: -50%;
			}
		}
	}

	&[data-swiper-slide-index='3'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				height: 220%;
				max-width: 220%;
				width: 220%;
				position: absolute;
				left: -85%;
				top: -30%
			}
		}
	}
}

.buildflow-images {
	&--with-3-imgs {
		&[data-swiper-slide-index='2'] {
			div[data-product-type*='TOP_FRAME'] {
				img {
					height: 120%;
					max-width: 120%;
					width: 120%;
					position: absolute;
					left: -10%;
					mix-blend-mode: multiply;
				}
			}
		}

		&[data-swiper-slide-index='1'] {
			div[data-product-type*='TOP_FRAME'] {
				img {
					height: 200%;
					max-width: 200%;
					width: 200%;
					position: absolute;
					left: 5%;
					bottom: -15%;
					mix-blend-mode: multiply;
					-o-object-fit: contain;
					object-fit: contain;
				}
			}
		}
	}

	&--with-5-imgs {
		&[data-swiper-slide-index='4'] {
			div[data-product-type*='TOP_FRAME'] {
				img {
					height: 200%;
					max-width: 200%;
					width: 200%;
					position: absolute;
					left: -50%;
					mix-blend-mode: multiply;
				}
			}
		}

		&[data-swiper-slide-index='3'] {
			div[data-product-type*='TOP_FRAME'] {
				img {
					height: 200%;
					max-width: 200%;
					width: 200%;
					position: absolute;
					left: -70%;
					bottom: 30%;
				}
			}
		}

		&[data-swiper-slide-index='2'] {
			div[data-product-type*='TOP_FRAME'] {
				img {
					height: 200%;
					max-width: 200%;
					width: 200%;
					position: absolute;
					left: 5%;
					bottom: -15%;
					mix-blend-mode: multiply;
					-o-object-fit: contain;
					object-fit: contain;
				}
			}
		}
	}
}

.image-container,
.main-image-container {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: $gray-1;
	overflow: hidden;

	&:not([data-product-type='ACCESSORY']) {
		img {
			height: 100%;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			margin: auto;
			padding-inline: clamp(1.6rem, 2vw, 4.8rem);
			font-family: 'object-fit: contain;';
			object-fit: contain;
			mix-blend-mode: multiply;
			aspect-ratio: 2 / 1;

			&.primaryImage {
				mix-blend-mode: normal;
			}
		}

		&::after {
			content: '';
			display: block;
			height: 0;
			padding-top: clamp(240px, 40vw, 668px);
		}
	}

	&[data-product-type='ACCESSORY'] {
		img {
			aspect-ratio: 1.55 / 1;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&[data-product-title='Handy Dandy Cleaning Kit'] {
		img {
			width: 100%;
			object-fit: cover;
			padding: 0;
			object-position: 0 25%;
		}
	}
}

img.primary-image {
	z-index: $zindex-2;
	position: absolute;
	mix-blend-mode: normal !important;

	&.lifestyle-top-frame {
		object-fit: contain !important;
		height: 18% !important;
		bottom: 19% !important;
	}

	&.thumbnail-lifestyle-top-frame {
		object-fit: contain !important;
		height: 1.2rem !important;
		bottom: 51% !important;
	}
}

img.secondary-image {
	z-index: $zindex-1;
}

.video-container {
	&:not([data-product-type='ACCESSORY']) {
		video,
		img {
			height: 100%;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
			margin: auto;
			object-fit: cover;
			aspect-ratio: 4 / 3;
		}

		img {
			mix-blend-mode: normal;
		}

		&::after {
			content: '';
			display: block;
			height: 0;
			padding-top: clamp(240px, 40vw, 668px);
		}
	}
}

.flag {
	padding: 2px 4px;
	color: $gray-0;
	background-color: $blue-2;
	border-radius: $radius-2;
	display: flex;
	flex-direction: row;
	align-items: center;
	position: absolute;
	top: clamp(32px, 4vw, 64px);
	left: clamp(32px, 4vw, 64px);
	font-weight: 500;
	font-size: 1.4rem;
	letter-spacing: 0.02em;
	z-index: $zindex-2;
}

.favorite-wrapper {
	position: absolute;
	right: 1.6rem;
	top: 1.6rem;
}

.image-loader {
	> div {
		top: 2rem;
	}

	@media screen and (min-width: $media-md) {
		> div {
			top: 6rem;
		}
	}
}

/*** Swiper Carousel Overrides ***/
/*********************************/
.swiper-button-prev,
.swiper-button-next {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	width: 4.8rem;
	height: 4.8rem;
	padding: 0;
	margin-top: calc($swiper-navigation-size / -2);
	color: $blue-2;
	background-color: $gray-0;
	border: 1.5px solid $gray-2;
	border-radius: $radius-round;
	transition: background-color 300ms ease;
	z-index: $zindex-1;
	cursor: pointer;

	svg {
		height: 1.6rem;
		stroke: $blue-2;
	}

	&.swiper-button-disabled {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}

	&:active,
	&:hover {
		background-color: $gray-2;
		transition: background-color 300ms ease;
	}

	&:focus-visible {
		box-shadow: $shadow-pink;
	}

	&:focus {
		outline: none;
	}

	@media screen and (max-width: $media-lg) {
		display: none;
	}
}

.swiper-button-prev {
	right: 6.4rem;
	left: auto;
	bottom: 0.8rem;
	top: unset;

	svg {
		stroke: $black;
		height: 2rem;
		width: 2rem;
	}

	&:active {
		border: 1px solid $gray-3;
	}

	&:focus-visible {
		box-shadow: unset;
		border: 1px solid $gray-3;
	}
}

.swiper-button-next {
	right: 0.8rem;
	left: auto;
	bottom: 0.8rem;
	top: unset;

	svg {
		stroke: $black;
		height: 2rem;
		width: 2rem;
	}

	&:active {
		border: 1px solid $gray-3;
	}

	&:focus-visible {
		box-shadow: unset;
		border: 1px solid $gray-3;
	}
}

.swiper-pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	transition: 300ms ease opacity;
	transform: translate3d(0, 0, 0);
	bottom: 0.5rem;
	pointer-events: none;

	&::after {
		content: '';
		height: 2rem;
		width: 11rem;
		position: absolute;
		border-radius: 1.6rem;
		background: $gray-1;
		opacity: 50%;
	}

	&.swiper-pagination-hidden {
		opacity: 0;
	}

	@media screen and (min-width: $media-lg) {
		display: none;
	}
}

.swiper-pagination-bullet-video,
.swiper-pagination-bullet {
	margin-inline: 0.4rem;
	height: 2.6rem;
	display: flex;
	justify-content: center;
	border-radius: $radius-6;
	align-items: center;
	position: relative;
	z-index: $zindex-2;
	pointer-events: all;

	&::after {
		content: '';
		width: 0.8rem;
		display: block;
		border-radius: $radius-6;
		height: 0.8rem;
		background: $blue-1;
		position: relative;
		opacity: 0.25;
		transition: width 200ms ease-in-out;
	}

	&:only-child {
		display: none;
	}

	&:focus-visible {
		box-shadow: $shadow-pink;
	}

	&.swiper-pagination-bullet-active-video::after,
	&.swiper-pagination-bullet-active::after {
		transition: width 200ms ease-in-out;
		width: 3.2rem;
		height: 0.8rem;
		border: none;
		opacity: inherit;
		background-color: $blue-1;
	}
}

.swiper-pagination-bullet-video {
	&:last-child::after {
		width: 0;
		height: 0;
		border-top: 0.4rem solid transparent;
		border-bottom: 0.4rem solid transparent;
		border-right: 0.4rem solid transparent;
		border-left: 0.8rem solid $gray-1;
		border-radius: 0;
		background: transparent;
	}


	&.swiper-pagination-bullet-active-video {
		&:last-child::after {
			width: 0;
			height: 0;
			border-top: 0.4rem solid transparent;
			border-bottom: 0.4rem solid transparent;
			border-right: 0.4rem solid transparent;
			border-left: 0.8rem solid $blue-1;
			border-radius: 0;
			background: transparent;
		}
	}
}

.swiper-pagination-lock {
	display: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
	cursor: pointer;
	z-index: $zindex-1;
}

.thumbnail-image {
	margin: 0 1rem 1rem;
	width: 7.2rem;
	height: 7.2rem;
	border: 2px solid $gray-2;
	border-radius: 1rem;
	cursor: pointer;
	background-color: $gray-1;
	position: relative;
	overflow: hidden;

	img {
		font-family: 'object-fit: contain;';
		object-fit: contain;
		mix-blend-mode: multiply;
	}

	&[role=button] {
		border: 2px solid $gray-2;
		background-color: $gray-1;
	}

	&[thumbnail-index='2-3/4-image=true'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				max-width: 200%;
				position: absolute;
				top: 0;
				left: -51%;
				height: 100%;
			}
		}
	}

	&[thumbnail-index='3-3/4-image=true'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				max-width: 300%;
				position: absolute;
				top: -40%;
				left: -120%;
				height: 150%;
			}
		}
	}

	&[thumbnail-index='1-3/4-image=false'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				max-width: 200%;
				position: absolute;
				top: 0;
				left: -51%;
				height: 100%;
			}
		}
	}

	&[thumbnail-index='2-3/4-image=false'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				max-width: 300%;
				position: absolute;
				top: 0;
				left: 15%;
				height: 150%;
			}
		}
	}

	&[thumbnail-index='3'],
	&[thumbnail-index='4'] {
		div[data-product-type*='BASE_FRAME'],
		div[data-product-type='ACCESSORY'] {
			width: 100%;
			height: 100%;

			img {
				padding: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top;
				mix-blend-mode: unset;
			}
		}
	}

	&[thumbnail-index='4-3/4-image=true'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				max-width: 300%;
				position: absolute;
				top: 0;
				left: 15%;
				height: 150%;
			}
		}
	}

	&[thumbnail-index='4-3/4-image=false'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				padding: 0;
				width: 68px;
				height: 68px;
				object-fit: cover;
				object-position: top;
				mix-blend-mode: unset;
			}
		}
	}

	div[data-product-type='LIFESTYLE'] {
		img {
			width: 68px;
			height: 68px;
			object-fit: cover;
		}
	}

	&[thumbnail-index='3-3/4-image=false'] {
		div[data-product-type*='TOP_FRAME'] {
			img {
				padding: 0;
				width: 72px;
				height: 72px;
				border-radius: 1rem;
				object-fit: cover;
				object-position: top;
				mix-blend-mode: unset;
			}
		}
	}

	&[thumbnail-index='0'],
	&[thumbnail-index='1'],
	&[thumbnail-index='2'] {
		div[data-product-type='ACCESSORY'] {
			img {
				padding: 0;
				width: 68px;
				height: 68px;
				object-fit: cover;
				object-position: top;
				mix-blend-mode: unset;
			}
		}
	}

	&[thumbnail-index='0'] {
		div[data-product-type='Gift Card'] {
			img {
				padding: 0 0.8rem;
				object-fit: cover;
				object-position: top;
				mix-blend-mode: unset;
			}
		}
	}

	&.video-thumbnail {
		div[data-product-type*='TOP_FRAME'],
		div[data-product-type*='BASE_FRAME'] {
			height: 100%;

			img {
				position: unset;
				max-width: 100%;
				mix-blend-mode: unset;
				height: 100%;
				object-fit: cover;
				border-radius: unset;
			}
		}
	}

	&:hover {
		border-color: $gray-3;
	}

	&:active {
		border-color: $blue-1;
	}
}

.active-thumbnail {
	border-color: $blue-1 !important;
}

.carousel-container {
	padding: unset;

	@media screen and (min-width: $media-lg) {
		padding: 2rem 0 0 2rem;
		position: sticky;
		top: 10.8rem;
	}
}

.thumbnail-container {
	height: fit-content;
	display: none;

	@media screen and (min-width: $media-lg) {
		display: block;
	}
}

.thumbnail-container-tops {
	@extend .thumbnail-container;
	top: 0;
}

.play-icon {
	position: absolute;
	left: 32%;
	top: 32%;
	z-index: $zindex-1;
}

.saveTag {
	position: absolute;
	top: 3rem;
	right: 0;
	z-index: 1000;
	@media screen and (max-width: $media-sm) {
		top: 1rem;
	}
}
